import React from 'react';

// React
import { Disclosure } from '@headlessui/react'

// Shared Components
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import Seo from "../../components/seo";


import HeaderImg from '../../images/features/testing-utility/header.png'
import Convenience from '../../images/features/testing-utility/the-convenience.png';
import Signal from '../../images/features/testing-utility/signal-status.png';
import chartsPerson from "../../images/home/charts-person.png";

// Heroicons
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Link } from 'gatsby';



const faqs = [
    {
        question: "How do I know if my central station is compatible with Tech Tester?",
        answer:
            `Please take a look at our list of currently compatible central stations at alarmtechtester.com/compatible-central-stations.`,
    },
    {
        question: "What do I need to setup an account with Tech Tester?",
        answer:
            "All you need to get started with Tech Tester is a valid Central Station log-in with the appropriate permissions allowed. After you link your credentials to Tech Tester, you are all ready to get started!",
    },
    {
        question: "I use multiple central stations. Does Tech Tester allow that?",
        answer:
            "Absolutely. Tech Tester will allow you to link as many central stations to your account as you need.",
    },
    {
        question: "My free trial has expired, how do I continue to use Tech Tester?",
        answer:
            "To continue using Tech Tester, please enter your payment details within your account screen. If you need help with payments, please feel free to reach out to our support team, who will gladly assist in getting you back up and running with Tech Tester.",
    },
    {
        question: "When will my credit card be billed for Tech Tester?",
        answer:
            "Your credit card will be billed on the 1st of each month. If you joined late in the month, your credit card charge will reflect that. You will pay a pro-rated amount, only for the time you were using Tech Tester.",
    },
    {
        question: "I would like one of my technicians to have full access. Is that possible?",
        answer:
            "Absolutely. With Tech Tester, you are in full control over how much (or how little) access your technicians have. You can change the access levels at any time.",
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TestingUtility() {

    return (
        <div>
            <Seo title="How it works" description="Learn how Tech Tester works, and how it can make your life easier." />
            <Header/>
            {/* Hero section */}
            <div className="bg-myGray-300">
                <div className={"pt-10 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"}>
                    <div className={"my-auto text-left "}>
                        <h1 className={"md:text-4xl text-3xl text-myGray-600 font-bold"}>Testing Utility</h1>
                        <h3 className={"text-myGray-600 text-lg pt-10"}>The Tech Tester testing utility allows technicians to easily see what sensors have communicated, and what sensors have yet to communicate.</h3>
                        <Link to={'/contact'}>
                            <button  className="mt-10 inline-flex px-4 py-2 text-base border border-transparent rounded-md shadow-sm text-sm text-black font-bold bg-yellowTT hover:bg-yellowTT-alt"
                            >START NOW</button></Link>
                    </div>
                    <div className={"m-auto"}>
                        <img
                            className=""
                            src={HeaderImg}
                            alt="Temp Item"
                        />
                    </div>
                </div>
            </div>



            {/* Alternating Feature Sections */}
            <div className="relative overflow-hidden">
                <div aria-hidden="true" className="absolute z-10 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"/>

                <div className="my-12 pt-6">
                    <div
                        className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div
                            className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Signal status at a glance
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500 pr-6">
                                        No more scanning through pages of signals looking for each zone you have tripped. Our testing utility groups all signals by their zone of communication so you can easily see the status of each sensor. This becomes more and more valuable on systems with a large set of sensors!                                  </p>
                                </div>
                            </div>
                        </div>
                        <div className="m-auto">
                            <div className="pl-4 mr-4 mr-2 lg::-mr-16 sm:pl-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className=""
                                    src={Signal}
                                    alt="Temp Item"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative mt-24 py-24 bg-myGray-200">
                    <div className="relative z-10 grid grid-cols-1 text-center place-items-center">
                        <div>
                            <img
                                className="w-64 h-auto"
                                src={chartsPerson}
                                alt="Tech Tester App User Interface"
                            />
                        </div>
                        <div className="mt-6 p-10">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                Beautiful Reporting
                            </h2>
                            <p className="mt-4 text-lg text-gray-500 max-w-5xl">
                                Send an elegant report branded with your logo that summarizes all of the signals by zone directly to the subscriber or to your back-office.                             </p>
                            <div className="mt-6">
                                <Link
                                    to="/features/work-queue"
                                    className="inline-flex bg-gradient-to-r from-silver-light to-silver-light bg-origin-border px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-md text-black hover:from-silver-alt hover:to-silver-alt"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="my-12 pt-6">
                    <div
                        className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div
                            className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        The convenience you've been begging for
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500 pr-6">
                                        No more calling-in to have someone else review the signal activity, all the information you need is in the palm of your hand with Tech Tester!                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="m-auto">
                            <div className="pl-4 mr-6 mr-6 lg:-mr-16 sm:pl-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className=""
                                    src={Convenience}
                                    alt="Temp Item"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* FAQ Section */}

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                        <div className="max-w-3xl mx-auto">
                            <h2 className="text-center text-3xl font-extrabold text-gray-900">Frequently asked questions</h2>
                            {faqs.map((faq) => (
                                <Disclosure as="div" key={faq.question} className="pt-6 bg-myGray-300 rounded-md m-5 p-5">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-gray-900">{faq.question}</span>
                                                    <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                          />
                        </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base text-gray-500">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}